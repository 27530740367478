import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import PageHeader from '../components/pageheader'
import Slider from '../components/slider'
import CallToAction from '../components/calltoaction'
import FlexContent from '../components/flexContent'

const SingleNews = ({
    data: {
        page,
        navigation,
        slider,
        cta
    },
    pageContext: {
        locale
    }
}) => {

    return (
        <Layout locale={locale} navigation={navigation} page={page}>
            <PageHeader page={page} locale={locale}/>
            <FlexContent page={page}/>
            <Slider slider={slider}/>
            <CallToAction cta={cta}/>
        </Layout>

    );
}

export default SingleNews

export const query = graphql`
    query($id: String!, $locale: String) {
         page: contentfulNieuws( node_locale: { eq: $locale }, id: { eq: $id } ) {
            ...NieuwsFragment
        }
        navigation: contentfulNavigation (node_locale: {eq: $locale}) {
            ...NavigationFragment
        }  
        cta: allContentfulCallToAction(filter: {node_locale: {eq: $locale}, pageLocation: {eq: "global"}}) {  
            edges {
                node {
                    ...CallToActionFragment
                }
            }
        }
        slider: allContentfulSlider(filter: {node_locale: {eq: $locale}}) {
            edges {
                node {
                    ...SliderFragment
                }
            }
        }
    }
`

